import React from 'react'
import {SignIn} from '../components/SignIn.js'

function login() {
  return (
    <div className='signIn'>
           <SignIn />
    </div>
  )
}

export default login